import {logoutCustomer} from "utils/common";

type HandleLogout = (data?: {token: string}) => void;

export const handleLogout: HandleLogout = (options) => {
  const token = options?.token;
  logoutCustomer();

  const destination =
    window.location.pathname === "/verify-account" ? "/" : window.location.pathname;

  window.location.href = [
    "/verify-account",
    `?destination=${encodeURIComponent(destination)}`,
    token ? `&orderToken=${token}` : "",
  ].join("");
};
